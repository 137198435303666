.responsive-chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
}

.chart-nav-button {
    flex: 0 0 auto;
    margin: 0 10px;
}

.number-cards-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 20px;
}

.number-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 768px) {
    .responsive-chart-container {
        flex-direction: column;
    }

    .chart-nav-button {
        margin: 10px 0;
    }

    .number-cards {
        flex-direction: row;
        justify-content: space-around;
    }

    .number-cards-container {
        justify-content: center;
        align-items: center;
    }
}

