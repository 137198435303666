.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Users-header {
  font-weight: normal;
  margin-right: 10px;
}

.Users-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.Users-chart {
  margin-top: 20px;
}

.custom-card {
  background-color: white; 
  border: 2px solid #99ccff; 
}

.custom-card .ant-card-actions {
  background-color: white;
  border-top: 2px solid #99ccff; 
}

.users-card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.user-card {
  display: flex;
  justify-content: center;
}

.user-number-cards-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.user-number-cards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.user-number-cards > .ant-card {
  margin-bottom: 20px; 
}

.graph-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.graph-wrapper {
  margin-left: 20px; 
}

.user-info-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.search-bar {
  margin-left: auto;
  margin-right: 20px;
}

.search-bar-input {
  width: 200px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

@media (min-width: 1200px) {
  .user-info-container {
    justify-content: space-around;
  }

  .graph-wrapper {
    margin-left: 0; 
  }
}

@media (max-width: 768px) {
  .user-number-cards {
    flex-direction: row;
    justify-content: space-around;
  }

  .user-number-cards-container {
    justify-content: center;
    align-items: center;
  }

  .user-number-cards > .ant-card {
    margin: 10px; 
  }

  .graph-wrapper {
    margin-left: 0; 
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}